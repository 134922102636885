import React from "react";
import "../../styles/Footer.css"; // Import the CSS file

const Footer = () => (
  <footer className="footer">
    <div className="logo">Soriano's Heating and Air Conditioning</div>
    <div className="contact-info">
      <p>Contact us</p>
      <p>Phone: (845) 531-1698</p>
      <p>Email: sorianoshvac@gmail.com</p>
      <div className="social-media">

        <a href="https://www.instagram.com/sorianoshvac/" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SlideShowManagement from "./SlideShowManagement";
import RequestsManagement from "./RequestsManagement";
import { AuthContext } from "../../context/AuthContext";
import "../../styles/ControlPanel.css";

const ControlPanel = () => {
  const [activeSection, setActiveSection] = useState("slideShow");
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);
    navigate("/login"); // Redirect to the login page after logout
  };

  const renderActiveSection = () => {
    switch (activeSection) {
      case "slideShow":
        return <SlideShowManagement />;
      case "requests":
        return <RequestsManagement />;
      default:
        return <SlideShowManagement />;
    }
  };

  return (
    <div className="control-panel">
      <h2>Control Panel</h2>
      <div className="control-panel-content">
        <div className="side-menu">
          <ul>
            <li
              className={activeSection === "slideShow" ? "active" : ""}
              onClick={() => setActiveSection("slideShow")}
            >
              Slide Show Management
            </li>
            <li
              className={activeSection === "requests" ? "active" : ""}
              onClick={() => setActiveSection("requests")}
            >
              Requests Management
            </li>
            <li className="logout" onClick={handleLogout}>
              Logout
            </li>
          </ul>
        </div>
        <div className="main-content">{renderActiveSection()}</div>
      </div>
    </div>
  );
};

export default ControlPanel;

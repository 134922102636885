import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import "../../styles/RequestsManagement.css";

const RequestsManagement = () => {
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const token = localStorage.getItem("accessToken"); // Ensure correct token retrieval
        if (!token) {
          throw new Error("No token found");
        }
        const res = await axiosInstance.get("/requests", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRequests(res.data);
      } catch (err) {
        console.error(err);
        // Redirect to login if token is invalid or missing
        if (err.response && err.response.status === 401) {
          window.location.href = "/login";
        }
      }
    };

    fetchRequests();
  }, []);

  const sortRequests = (requestsToSort = requests) => {
    const sortedRequests = [...requestsToSort].sort((a, b) => {
      const statusOrder = ["", "Accepted", "Completed", "Declined"];
      const statusA = statusOrder.indexOf(a.status);
      const statusB = statusOrder.indexOf(b.status);

      if (statusA !== statusB) {
        return sortOrder === "asc" ? statusA - statusB : statusB - statusA;
      }

      return sortOrder === "asc"
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedRequests;
  };

  const handleAccept = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axiosInstance.put(
        `/requests/${id}`,
        { status: "Accepted" },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setRequests((prevRequests) => {
        const updatedRequests = prevRequests.map((request) =>
          request._id === id ? { ...request, status: "Accepted" } : request
        );
        return sortRequests(updatedRequests);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDecline = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axiosInstance.put(
        `/requests/${id}`,
        { status: "Declined" },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setRequests((prevRequests) => {
        const updatedRequests = prevRequests.map((request) =>
          request._id === id ? { ...request, status: "Declined" } : request
        );
        return sortRequests(updatedRequests);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleComplete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axiosInstance.put(
        `/requests/${id}`,
        { status: "Completed" },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setRequests((prevRequests) => {
        const updatedRequests = prevRequests.map((request) =>
          request._id === id ? { ...request, status: "Completed" } : request
        );
        return sortRequests(updatedRequests);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axiosInstance.delete(`/requests/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRequests((prevRequests) => {
        const updatedRequests = prevRequests.filter(
          (request) => request._id !== id
        );
        return sortRequests(updatedRequests);
      });
      setShowModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSort = () => {
    setRequests(sortRequests());
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const truncateText = (text, length) => {
    if (!text) return "";
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <div className="requests-management">
      <h3>Manage Requests</h3>
      <table className="requests-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Service</th>
            <th className="hide-on-mobile">Description</th>
            <th className="hide-on-mobile">Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th onClick={handleSort} style={{ cursor: "pointer" }}>
              Status {sortOrder === "asc" ? "▲" : "▼"}
            </th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request) => (
            <tr
              key={request._id}
              className={`status-${request.status.toLowerCase()}`}
              onClick={() => {
                setSelectedRequest(request);
                setShowModal(true);
              }}
            >
              <td>{new Date(request.createdAt).toLocaleDateString()}</td>
              <td>{request.name}</td>
              <td>{request.serviceType}</td>
              <td className="hide-on-mobile">
                {truncateText(request.description, 20)}
              </td>
              <td className="hide-on-mobile">
                {truncateText(request.address, 20)}
              </td>
              <td>{request.phone}</td>
              <td>{truncateText(request.email, 20)}</td>
              <td>{request.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="legend">
        <p>
          <span className="status-accepted">●</span> Accepted
        </p>
        <p>
          <span className="status-completed">●</span> Completed
        </p>
        <p>
          <span className="status-declined">●</span> Declined
        </p>
      </div>

      {showModal && selectedRequest && (
        <div className="modal">
          <div className="modal-content">
            <h4>Service Request Details</h4>
            <p>
              <strong>Date:</strong>{" "}
              {new Date(selectedRequest.createdAt).toLocaleDateString()}
            </p>
            <p>
              <strong>Name:</strong> {selectedRequest.name}
            </p>
            <p>
              <strong>Service:</strong> {selectedRequest.serviceType}
            </p>
            <p>
              <strong>Description:</strong> {selectedRequest.description}
            </p>
            <p className="hide-on-mobile">
              <strong>Address:</strong> {selectedRequest.address}
            </p>
            <p>
              <strong>Phone:</strong> {selectedRequest.phone}
            </p>
            <p>
              <strong>Email:</strong> {selectedRequest.email}
            </p>
            <p>
              <strong>Status:</strong> {selectedRequest.status}
            </p>
            <p>
              <strong>Preferred Contact:</strong>{" "}
              {selectedRequest.preferredContact}
            </p>
            <p>
              <strong>Preferred Days:</strong>{" "}
              {selectedRequest.preferredDays
                ? selectedRequest.preferredDays.join(", ")
                : "N/A"}
            </p>
            <p>
              <strong>Preferred Time:</strong>{" "}
              {selectedRequest.preferredTime || "N/A"}
            </p>
            <button onClick={() => handleAccept(selectedRequest._id)}>
              Accept
            </button>
            <button onClick={() => handleDecline(selectedRequest._id)}>
              Decline
            </button>
            <button onClick={() => handleComplete(selectedRequest._id)}>
              Complete
            </button>
            <button onClick={() => handleDelete(selectedRequest._id)}>
              Delete
            </button>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestsManagement;

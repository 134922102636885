import React from "react";
import "../../styles/ServiceCard.css";

const ServiceCard = ({ image, title, description }) => (
  <div className="service-card">
    <div
      className="service-card-icon"
      style={{ backgroundImage: `url(${image})` }}
    ></div>
    <h3>{title}</h3>
    <div className="service-card-description">
      <p>{description}</p>
    </div>
  </div>
);

export default ServiceCard;

import React from "react";
import styles from "../../styles/AboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles["about-us-container"]}>
      <img
        src="../../assets/images/services_ac.png"
        alt="About Us image"
        className={styles["about-image"]}
      />
      <section className={styles["about-section"]}>
        <h1>About Us</h1>
        <p>
          Welcome to Soriano's Heating and Air conditioning, where our
          commitment to your comfort feels like family. I founded this company
          with a clear mission: to provide the best in HVAC services with a
          personal touch that makes every client feel at home.<br /><br />
          
          With a dedicated team of certified professionals, we bring years of
          experience and expertise to every project, ensuring your spaces are
          not only comfortable but also healthy and energy-efficient. Our team
          is skilled in the latest HVAC technologies and practices, offering
          innovative, efficient solutions tailored to meet your unique needs.<br /><br />
          
          We are passionate about sustainability and incorporate eco-friendly
          practices and products, striving to make a positive impact on the
          environment while enhancing your indoor air quality.<br /><br />
          
          At Soriano's Heating and Air conditioning, we pride ourselves on
          building lasting relationships based on trust and satisfaction. We
          believe in personalized service, and our team stands behind every
          installation, maintenance, and repair with a commitment to quality and
          your complete satisfaction.<br /><br />
          
          Whether you are navigating your first winter with us or upgrading your
          cooling systems for the summer, you can rely on us to provide
          exceptional service every step of the way. Let us handle the
          complexities of HVAC so you can enjoy your environment, worry-free.
        </p>
      </section>
      <section className={styles["mission-statement"]}>
        <h1>Our Mission</h1>
        <p>
          Our mission is to treat every home and business like our own,
          providing innovative and efficient HVAC solutions that ensure comfort
          and improve air quality.<br /><br />
          
          We are dedicated to serving our community with warmth and integrity,
          delivering exceptional service and sustainable practices. Our
          commitment to family values, reliability, and customer satisfaction
          drives us to create optimal environments for all, making us your
          trusted partner in climate control through every season.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;

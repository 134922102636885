// src/components/ServiceRequestForm.js

import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/ServiceRequestForm.css";

const ServiceRequestForm = () => {
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [preferredContact, setPreferredContact] = useState("Email");
  const [preferredDays, setPreferredDays] = useState([]);
  const [preferredTime, setPreferredTime] = useState("");
  const [showDaysDropdown, setShowDaysDropdown] = useState(false);
  const [showTimeDropdown, setShowTimeDropdown] = useState(false);
  const daysDropdownRef = useRef(null);
  const timeDropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const type = query.get("serviceType");
    if (type) {
      setServiceType(type);
    }
  }, [location]);

  const handleDayChange = (day) => {
    setPreferredDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const address = `${address1}, ${address2}, ${city}, ${state}, ${zip}`;
    const formData = {
      name,
      address,
      phone,
      email,
      description,
      serviceType,
      additionalDetails,
      preferredContact,
      preferredDays,
      preferredTime,
    };

    try {
      await axiosInstance.post("/requests", formData);
      alert(
        "Request sent, we will contact you about your inquiry in less than two business days."
      );
      navigate("/"); // Redirect to the home page
    } catch (err) {
      console.error(err);
      alert("There was an error submitting your request. Please try again.");
    }
  };

  const toggleDaysDropdown = () => {
    setShowDaysDropdown(!showDaysDropdown);
  };

  const toggleTimeDropdown = () => {
    setShowTimeDropdown(!showTimeDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      daysDropdownRef.current &&
      !daysDropdownRef.current.contains(event.target)
    ) {
      setShowDaysDropdown(false);
    }
    if (
      timeDropdownRef.current &&
      !timeDropdownRef.current.contains(event.target)
    ) {
      setShowTimeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="service-request-form">
      <h2>Request Service</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Type of Service:
          <select
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}
            required
          >
            <option value="" disabled>
              Select a service
            </option>
            <option value="Heating">Heating</option>
            <option value="Ventilation">Ventilation</option>
            <option value="Air Conditioning">Air Conditioning</option>
            <option value="Other">Other</option>
            <option value="Multiple">Multiple</option>
          </select>
        </label>
        {serviceType === "Other" || serviceType === "Multiple" ? (
          <label>
            Specify the service needed:
            <textarea
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
              className="additional-details-textarea"
              required
            ></textarea>
          </label>
        ) : null}
        <label>
          Description:
          <textarea
            placeholder="You may add details about the needed service here, you may add links to images"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </label>

        <div className="divider"></div>

        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label>
          Address Line 1:
          <input
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
            required
          />
        </label>
        <label>
          Address Line 2 (Optional):
          <input
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          />
        </label>
        <label>
          City:
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </label>
        <label>
          State:
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          />
        </label>
        <label>
          Zip Code:
          <input
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            required
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          Preferred Method of Communication:
          <select
            value={preferredContact}
            onChange={(e) => setPreferredContact(e.target.value)}
            required
          >
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
          </select>
        </label>

        <label>
          Preferred Days:
          <div className="dropdown" ref={daysDropdownRef}>
            <button
              type="button"
              onClick={toggleDaysDropdown}
              style={{ color: preferredDays.length > 0 ? "#000" : "#999" }}
            >
              {preferredDays.length > 0
                ? preferredDays.map((day) => day.substring(0, 3)).join(", ")
                : "Select Days"}
            </button>
            {showDaysDropdown && (
              <div className="dropdown-menu">
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day) => (
                  <label key={day}>
                    <input
                      type="checkbox"
                      value={day}
                      checked={preferredDays.includes(day)}
                      onChange={() => handleDayChange(day)}
                    />
                    {day}
                  </label>
                ))}
              </div>
            )}
          </div>
        </label>

        <label>
          Preferred Time:
          <div className="dropdown" ref={timeDropdownRef}>
            <button
              type="button"
              onClick={toggleTimeDropdown}
              style={{ color: preferredTime ? "#000" : "#999" }}
            >
              {preferredTime ? preferredTime : "Select Time"}
            </button>
            {showTimeDropdown && (
              <div className="dropdown-menu">
                {["Morning", "Afternoon"].map((time) => (
                  <label key={time}>
                    <input
                      type="radio"
                      value={time}
                      checked={preferredTime === time}
                      onChange={(e) => setPreferredTime(e.target.value)}
                    />
                    {time}
                  </label>
                ))}
              </div>
            )}
          </div>
        </label>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ServiceRequestForm;

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import LandingPage from "./components/landing/LandingPage";
import ControlPanel from "./components/controlpanel/ControlPanel";
import LoginPage from "./components/auth/LoginPage";
import ServiceRequestForm from "./components/landing/ServiceRequestForm";
import AuthProvider, { AuthContext } from "./context/AuthContext";
import AboutUs from "./components/about-us/AboutUs";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => (
  <AuthProvider>
    <Router>
      <div className="App">
        <Header companyName="Soriano's Heating and Air Conditioning" />
        <main>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/control-panel"
              element={
                <ProtectedRoute>
                  <ControlPanel />
                </ProtectedRoute>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/request-service" element={<ServiceRequestForm />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  </AuthProvider>
);

export default App;

import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import "../../styles/Slideshow.css";

const fallbackImageURL = "https://via.placeholder.com/600x400?text=Image+not+found";

const Slideshow = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPopOutOpen, setIsPopOutOpen] = useState(false);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Date.now() - lastInteractionTime > 5000) { // 5 seconds cooldown
        setCurrentSlide((current) => (current + 1) % slides.length);
      }
    }, 2000); // Checks every 2 seconds
    return () => clearInterval(intervalId);
  }, [lastInteractionTime, slides.length]);

  useEffect(() => {
    axiosInstance.get("/slides")
      .then((res) => {
        setSlides(res.data);
      })
      .catch((err) => {
        console.error("Error fetching slides:", err);
      });
  }, []);

  const closePopOut = () => setIsPopOutOpen(false);

  const goToPreviousSlide = () => {
    setLastInteractionTime(Date.now());
    setCurrentSlide((current) => (current - 1 + slides.length) % slides.length);
  };

  const goToNextSlide = () => {
    setLastInteractionTime(Date.now());
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  return (
    <div className="slideshow">
      {slides.length > 0 && (
        <div className="slides-container" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {slides.map((slide, index) => (
            <div className="slide" key={index} onClick={() => setIsPopOutOpen(true)}>
              <img
                src={slide.imageURL || fallbackImageURL}
                alt={slide.description}
                onError={(e) => e.target.src = fallbackImageURL}
              />
            </div>
          ))}
        </div>
      )}
      {slides.length > 1 && (
        <>
          <button className="prev" onClick={goToPreviousSlide}>&lt;</button>
          <button className="next" onClick={goToNextSlide}>&gt;</button>
        </>
      )}
      {isPopOutOpen && (
        <div className="pop-out" onClick={closePopOut}>
          <img src={slides[currentSlide].imageURL} alt={slides[currentSlide].description} onClick={(e) => e.stopPropagation()} />
        </div>
      )}
    </div>
  );
};

export default Slideshow;

import React from "react";
import Slideshow from "./Slideshow.js";
import ServiceCard from "./ServiceCard.js";
import "../../styles/LandingPage.css";
// Import images
import acImage from "../../assets/images/services_ac.png";
import heatImage from "../../assets/images/services_heat.png";
import ventilationImage from "../../assets/images/services_ventilation.png";

const services = [
  {
    image: acImage,
    title: "Air Conditioning",
    description:
      "Professional air conditioning installation and repair services.",
  },
  {
    image: heatImage,
    title: "Heating Systems",
    description: "Expert heating system installation and maintenance.",
  },
  {
    image: ventilationImage,
    title: "Ventilation",
    description: "Efficient ventilation solutions for your home or office.",
  },
];

const LandingPage = () => (
  <div className="landing-page">
    <section className="hero">
      <Slideshow />
    </section>
    <section className="services-overview">
      <h2>Our Services</h2>
      <p>We offer a wide range of HVAC services to meet your needs...</p>
      <div className="services-cards">
        {services.map((service) => (
          <ServiceCard
            key={service.title}
            image={service.image}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </section>
    <section className="about-us">
      <h2>About Soriano's HVAC</h2>
      <p>
        Learn more about our company and our commitment to providing top-notch
        HVAC services...
      </p>
    </section>
  </div>
);

export default LandingPage;
